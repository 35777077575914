<template>
  <div class="solveWrapper">
    <div class="pcWrapper" v-if="!isMobile">
      <div class="firstBannerWrapper">
        <img src="../../assets/images/solve/firstback.png" alt="" />
        <!-- <div class="textBox">
          <div class="firstRow1">行业格局已经发生巨变</div>
          <div class="firstRow2">我们为您提供场景化的</div>
          <div class="firstRow3">深度行业解决方案</div>
        </div> -->
        <div class="textBox">
          <div class="firstRow1">为银行提供场景解决方案</div>
          <div class="firstRow2">助力银行数字化转型</div>
          <!-- <div class="firstRow3">深度行业解决方案</div> -->
        </div>
      </div>
      <div class="secondWrapper">
        <div class="secondTitle">SQUARE互联网金融2.0整体解决方案</div>
        <div class="secondContentWrapper">
          <div class="topBox">
            <img src=".././../assets/images/solve/second01.png" alt="" />
          </div>
          <div class="bottomBox">
            <img src=".././../assets/images/solve/second02.png" alt="" />
            <div class="playBtn" @click="videoBtnDidClick">
              <!-- <img src="../../assets/images/solve/play.png" alt=""> -->
            </div>
          </div>
        </div>
      </div>
      <div class="forthWrapper">
        <div class="forthTitle">我能为您提供的产品和服务</div>
        <div class="forthContent">
          <div class="forthRow">
            <div
              class="forthRowItem"
              v-for="(item, index) in forthDataList"
              :key="index + 20"
            >
              <div class="itemTitle">{{ item.title }}</div>
              <div class="itemBottomName">{{ item.name }}</div>
              <div class="imageBox">
                <img :src="item.imgUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fifthTitle">经典案例</div>
      <div class="fifthWrapper">
        <div class="imgBox">
          <div class="textDiv">
            <span class="left_indexSpan" :class="{'blackGroundColor': currentIndex === 4}">{{ currentBean.id }}</span>
            <span class="textSpan" :class="{'blackGroundColor': currentIndex === 4}">{{ currentBean.title }}</span>
          </div>

          <img
            v-if="currentIndex === 1"
            class="leftShowImg"
            src="../../assets/images/solve/fifth/fifth_l_1.png"
            alt=""
          />
          <img
            v-if="currentIndex === 2"
            class="leftShowImg"
            src="../../assets/images/solve/fifth/fifth_l_2.png"
            alt=""
          />
          <img
            v-if="currentIndex === 3"
            class="leftShowImg"
            src="../../assets/images/solve/fifth/fifth_l_3.png"
            alt=""
          />
          <img
            v-if="currentIndex === 4"
            class="leftShowImg"
            src="../../assets/images/solve/fifth/fifth_l_4.png"
            alt=""
          />
        </div>
        <div class="fifthRightDiv">
          <div
            v-for="(item, index) in fifthDataList"
            :key="index + 10"
            @click="imgDidClick(item.id)"
          >
            <img :src="item.imgUrl" alt="" />
            <span class="indexSpan" :class="`indexSpan_${index + 1}`">{{
              item.id
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="m_wrapper">
      <div class="m_firstBannerWrapper">
        <img src="../../assets/images/solve/phone/firstback.png" alt="" />
        <div class="m_textBox">
          <div class="firstRow1">为银行提供场景解决方案</div>
          <div class="firstRow2">助力银行数字化转型</div>
          <!-- <div class="firstRow3">深度行业解决方案</div> -->
        </div>
      </div>
      <div class="m_secondWrapper">
        <!-- <MobileTitle title="SQUARE互联网金融2.0整体解决方案"> </MobileTitle> -->
        <div class="m_secondTitle">SQUARE互联网金融2.0整体解决方案</div>
        <div class="m_secondContentWrapper">
          <div class="m_topBox">
            <img src=".././../assets/images/solve/phone/second01.png" alt="" />
          </div>
          <div class="m_bottomBox">
            <img src=".././../assets/images/solve/phone/second02.png" alt="" />
            <div class="m_playBtn" @click="videoBtnDidClick"></div>
          </div>
        </div>
      </div>
      <MobileTitle title="我能为您提供的产品和服务"> </MobileTitle>
      <div class="m_forthWrapper">
        <div class="m_forthContent">
          <div
            class="m_forthRowItem"
            v-for="(item, index) in m_forthDataList"
            :key="index + 200"
          >
            <div class="m_itemTitle">{{ item.title }}</div>
            <div class="m_itemBottomName">{{ item.name }}</div>
            <div class="m_imageBox">
              <img :src="item.imgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
      <MobileTitle title="经典案例"> </MobileTitle>
      <div class="m_fifthWrapper">
        <div class="m_imgBox">
          <div class="m_textDiv">
            <span class="m_left_indexSpan" :class="{'blackGroundColor': m_currentIndex === 4}">{{ m_currentBean.id }}</span>
            <span class="m_textSpan" :class="{'blackGroundColor': m_currentIndex === 4}">{{ m_currentBean.title }}</span>
          </div>
          <img
            v-if="m_currentIndex === 1"
            class="leftShowImg"
            src="../../assets/images/solve/phone/fifth/fifth_l_01.png"
            alt=""
          />
          <img
            v-if="m_currentIndex === 2"
            class="leftShowImg"
            src="../../assets/images/solve/phone/fifth/fifth_l_02.png"
            alt=""
          />
          <img
            v-if="m_currentIndex === 3"
            class="leftShowImg"
            src="../../assets/images/solve/phone/fifth/fifth_l_03.png"
            alt=""
          />
          <img
            v-if="m_currentIndex === 4"
            class="leftShowImg"
            src="../../assets/images/solve/phone/fifth/fifth_l_04.png"
            alt=""
          />
        </div>
        <div class="m_fifthRightDiv" @click="phoneFifthRightDivDidClick()">
          <div>
            <img
            v-if="m_currentIndex === 1"
            class="leftShowImg"
            src="../../assets/images/solve/phone/fifth/fifth_r_02.png"
            alt=""
          />
          <img
            v-if="m_currentIndex === 2"
            class="leftShowImg"
            src="../../assets/images/solve/phone/fifth/fifth_r_03.png"
            alt=""
          />
          <img
            v-if="m_currentIndex === 3"
            class="leftShowImg"
            src="../../assets/images/solve/phone/fifth/fifth_r_04.png"
            alt=""
          />
          <img
            v-if="m_currentIndex === 4"
            class="leftShowImg"
            src="../../assets/images/solve/phone/fifth/fifth_r_01.png"
            alt=""
          />
           
          </div>
          <span class="digitalSpan">{{showRightSpan}}</span>
        </div>
      </div>
    </div>
    <video-player :videoSrc="videoUrl" />
  </div>
</template>

<script>
import videoPlayer from "../video/index";
import { isMobile } from "../../utils/index";
import MobileTitle from "../../components/phone/mobileTitle.vue";

export default {
  components: { videoPlayer, MobileTitle },
  data() {
    return {
      isMobile: false,
      videoState: this.$store.state.account.videoState,
      videoUrl:
        "https://img.i2soft.net/%E8%89%BE%E8%9E%8D%E8%BD%AF%E4%BB%B6SQUARE%E5%AE%9E%E6%96%BD%E6%96%B9%E6%B3%95%E8%AE%BA.mp4",
      m_forthDataList: [
        {
          id: "1",
          title: "渠道类",
          name: "个人手机银行、企业手机银行、个人网银、企业网银、小程序、直销银行",
          imgUrl: require("../../assets/images/solve/phone/forth01.png"),
        },
        {
          id: "2",
          title: "平台类",
          name: "业务能力中台、融资中台、营销中台",
          imgUrl: require("../../assets/images/solve/phone/forth02.png"),
        },
        {
          id: "3",
          title: "开发平台类",
          name: "分布式服务开发平台（FAST）；跨平台前端开发平台-Lark",
          imgUrl: require("../../assets/images/solve/phone/forth03.png"),
        },
        {
          id: "4",
          title: "工具类",
          name: "互联网+可信身份认证平台、互联网+全渠道运营服务平台",
          imgUrl: require("../../assets/images/solve/phone/forth04.png"),
        },
        {
          id: "5",
          title: "运营类",
          name: " 银行商城联合运营服务、小程序/公众号/服务号/营销活动/直播/获客展业运营服务",
          imgUrl: require("../../assets/images/solve/phone/forth05.png"),
        },
      ],
      forthDataList: [
        {
          id: "1",
          title: "渠道类",
          name: "个人手机银行、企业手机银行、个人网银、企业网银、小程序、直销银行",
          imgUrl: require("../../assets/images/solve/forth01.png"),
        },
        {
          id: "2",
          title: "平台类",
          name: "业务能力中台、融资中台、营销中台",
          imgUrl: require("../../assets/images/solve/forth02.png"),
        },
        {
          id: "3",
          title: "开发平台类",
          name: "分布式服务开发平台（FAST）；跨平台前端开发平台-Lark",
          imgUrl: require("../../assets/images/solve/forth03.png"),
        },
        {
          id: "4",
          title: "工具类",
          name: "互联网+可信身份认证平台、互联网+全渠道运营服务平台",
          imgUrl: require("../../assets/images/solve/forth04.png"),
        },
        {
          id: "5",
          title: "运营类",
          name: "银行商城联合运营服务、小程序/公众号/服务号/营销活动/直播/获客展业运营服务",
          imgUrl: require("../../assets/images/solve/forth05.png"),
        },
      ],
      fifthDataAll: [
        {
          id: "01",
          title: "现金管理案例：上海银行现金管理平台",
          image_l_Url: require("../../assets/images/solve/fifth/fifth_l_1.png"),
          imgUrl: require("../../assets/images/solve/fifth/fifth_r_01.png"),
        },
        {
          id: "02",
          title: "直销银行案例：上海银行  上行快线",
          image_l_Url: require("../../assets/images/solve/fifth/fifth_l_2.png"),
          imgUrl: require("../../assets/images/solve/fifth/fifth_r_02.png"),
        },
        {
          id: "03",
          title: "金融电商案例：工行“融e购”金融电商平台",
          image_l_Url: require("../../assets/images/solve/fifth/fifth_l_3.png"),
          imgUrl: require("../../assets/images/solve/fifth/fifth_r_03.png"),
        },
        {
          id: "04",
          title: "现金管理案例：光大银行现金管理云平台",
          image_l_Url: require("../../assets/images/solve/fifth/fifth_l_4.png"),
          imgUrl: require("../../assets/images/solve/fifth/fifth_r_04.png"),
        },
      ],
      m_fifthDataAll: [
        {
          id: "01",
          title: "现金管理案例：上海银行现金管理平台",
          image_l_Url: require("../../assets/images/solve/fifth/fifth_l_1.png"),
          imgUrl: require("../../assets/images/solve/fifth/fifth_r_01.png"),
        },
        {
          id: "02",
          title: "直销银行案例：上海银行  上行快线",
          image_l_Url: require("../../assets/images/solve/fifth/fifth_l_2.png"),
          imgUrl: require("../../assets/images/solve/fifth/fifth_r_02.png"),
        },
        {
          id: "03",
          title: "金融电商案例：工行“融e购”金融电商平台",
          image_l_Url: require("../../assets/images/solve/fifth/fifth_l_3.png"),
          imgUrl: require("../../assets/images/solve/fifth/fifth_r_03.png"),
        },
        {
          id: "04",
          title: "现金管理案例：光大银行现金管理云平台",
          image_l_Url: require("../../assets/images/solve/fifth/fifth_l_4.png"),
          imgUrl: require("../../assets/images/solve/fifth/fifth_r_04.png"),
        },
      ],
      fifthDataList: [],
      currentBean: {
        id: "01",
        title: "现金管理案例：上海银行现金管理平台",
        image_l_Url: require("../../assets/images/solve/fifth/fifth_l_1.png"),
        imgUrl: require("../../assets/images/solve/fifth/fifth_r_01.png"),
      },
      m_currentBean: {
        id: "01",
        title: "现金管理案例：上海银行现金管理平台",
        image_l_Url: require("../../assets/images/solve/phone/fifth/fifth_l_01.png"),
        imgUrl: require("../../assets/images/solve/phone/fifth/fifth_r_01.png"),
      },
      m_currentIndex: 1,
      currentIndex: 1,
      imgUrl: "../../assets/images/solve/fifth/fifth_l_1.png",
    };
  },
  computed: {
    showRightSpan() {
      let showResult = 2;
      if(this.m_currentIndex <= 3){
        showResult = this.m_currentIndex +1;
      }else{
        showResult = 1;
      }
      return '0' + String(showResult);
    }
  },
  created() {
    var arrCopy = [...this.fifthDataAll];
    this.currentBean = arrCopy[0];
    this.fifthDataList = arrCopy.filter((item) => item.id !== "01");
  },
  mounted() {
    this.isMobile = isMobile();
    window.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  methods: {
    videoBtnDidClick() {
      this.$store.commit("TO_VIDEOS");
    },
    imgDidClick(index) {
      var arrCopy = [...this.fifthDataAll];
      this.currentBean = arrCopy.filter((item) => item.id === index)[0];
      var result = arrCopy.filter((item) => item.id !== index);
      this.currentIndex = parseInt(this.currentBean.id);
      this.fifthDataList = [];
      this.fifthDataList = result;
    },
    phoneFifthRightDivDidClick(){
      this.m_currentIndex += 1;
      if(this.m_currentIndex > 4) {
        this.m_currentIndex = 1;
      }
      this.m_currentBean = this.m_fifthDataAll[this.m_currentIndex - 1];
    },
    getScroll() {
      // this.indexNumTop = $("#index_num").offset().top;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
      ) {
        //设置滚动大于300时的执行内容
        // console.log(
        //   "document.documentElement.scrollTop=",
        //   document.documentElement.scrollTop
        // );
        this.$store.commit("TO_AfterScroll");
      } else {
        this.$store.commit("TO_AfterScroll_false");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.solveWrapper {
  background: #fff;
  .pcWrapper {
    .firstBannerWrapper {
      position: relative;
      img {
        margin: 0 auto;
        display: block;
        width: 100%;
        height: auto;
      }
      .textBox {
        position: absolute;
        top: 212px;
        right: 10%;
        width: 460px;
        height: 210px;
        // background: tomato;
        text-align: right;
        .firstRow1 {
          color: #034579;
          font-size: 40px;
          font-weight: bold;
        }
        .firstRow2 {
          color: #0497d1;
          font-weight: bold;
          font-size: 40px;
        }
        .firstRow3 {
          color: #0497d1;
          font-size: 30px;
        }
      }
    }
    .secondWrapper {
      .secondTitle {
        width: 100%;
        height: 62px;
        margin-top: 40px;
        text-align: center;
        color: #333;
        font-size: 30px;
        // background: green;
      }
      .secondContentWrapper {
        width: 100%;
        .topBox {
          width: 100%;
          margin: 2px auto;
          display: flex;
          justify-content: center;
          align-items: center;
          // background: tomato;
          img {
            width: 100%;
            height: auto;
            // background: green;
          }
        }
        .bottomBox {
          width: 80%;
          position: relative;
          margin: 0 auto;
          cursor: pointer;
          img {
            width: 100%;
            height: auto;
          }
          .playBtn {
            width: 50px;
            height: 50px;
            // background: pink;
            position: absolute;
            top: 50%;
            left: 47.5%;
            transform: translate(-50%, -50%);
            // z-index: 100;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .forthWrapper {
      width: 100%;
      background: #f6f7fb;
      overflow: hidden;
      padding-bottom: 40px;
      .forthTitle {
        text-align: center;
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 50px;
      }
      .forthContent {
        width: 80%;
        margin: 24px auto;
        // background: green;
        .forthRow {
          display: flex;
          // width: 18%;
          justify-content: space-around;
          font-size: 14px;
          text-align: center;
          .forthRowItem {
            background-image: linear-gradient(#f5f6f8, #ffffff);
            border: 2px solid #fff;
            width: 17%;
            .itemTitle {
              font-size: 18px;
              margin-top: 20px;
            }
            .itemBottomName {
              margin-top: 20px;
              height: 48px;
              font-size: 13px;
              margin-left: 5px;
              margin-right: 5px;
              text-align: center;
              // background: red;
              // word-break:break-all;
            }
            .imageBox {
              width: 100%;
              height: 140px;
              display: flex;
              justify-content: center;
              img {
                margin-top: 25px;
                width: 120px;
                height: 120px;
              }
            }
          }
        }
      }
    }
    .fifthTitle {
      width: 100%;
      color: #333;
      background: #ffffff;
      font-size: 30px;
      text-align: center;
      padding: 30px 0;
    }
    .fifthWrapper {
      position: relative;
      overflow: hidden;
      .imgBox {
        position: relative;
        .textDiv {
          position: absolute;
          top: 80px;
          left: 15%;
          display: flex;
          justify-content: center;
          align-items: center;
          .left_indexSpan {
            font-size: 40px;
          }
          .blackGroundColor {
            color: #fff;
          }
          .textSpan {
            margin-left: 5px;
            font-size: 18px;
          }
          .blackGroundColor {
            color: #fff;
          }
        }
        .leftShowImg {
          width: 76.5%;
          height: auto;
          float: left;
          margin-bottom: 50px;
        }
      }
      .fifthRightDiv {
        width: 23.5%;
        // height: 100%;
        // background: rebeccapurple;
        float: right;
        display: flex;
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
        .indexSpan {
          position: absolute;
          bottom: 45px;
          width: 33.33%;
          z-index: 100;
          font-size: 30px;
          color: #333;
        }
        .indexSpan_1 {
          left: 0;
        }
        .indexSpan_2 {
          left: 33.33%;
        }
        .indexSpan_3 {
          left: 66.66%;
        }
      }
      .fifthTitle {
        width: 100%;
        position: absolute;
        color: #333;
        background: #ffffff;
        font-size: 30px;
        text-align: center;
        padding: 30px 0;
      }
    }
  }
  @media screen and (max-width: 479px) {
    .m_firstBannerWrapper {
      width: 100%;
      overflow: hidden;
      position: relative;
      img {
        margin: 0 auto;
        display: block;
        width: 100%;
        // height: 339px;
        height: auto;
      }
      .m_textBox {
        position: absolute;
        top: 35%;
        left: 15px;
        width: 180px;
        height: 70px;
        // background: tomato;
        text-align: left;
        .firstRow1 {
          color: #034579;
          font-size: 16px;
        }
        .firstRow2 {
          color: #0497d1;
          font-size: 16px;
          font-weight: bolder;
        }
        .firstRow3 {
          color: #0497d1;
          font-size: 12px;
        }
      }
    }
    .m_secondWrapper {
      .m_secondTitle {
        width: 100%;
        height: 62px;
        margin-top: 40px;
        text-align: center;
        color: #333;
        font-size: 16px;
        // background: green;
        font-family: PingFangSC-Semibold;
      }
      .m_secondContentWrapper {
        width: 100%;
        // height: 500px;
        // margin-bottom: 30px;
        // background: pink;
        .m_topBox {
          width: 100%;
          margin: 2px auto;
          display: flex;
          justify-content: center;
          align-items: center;
          // background: tomato;
          img {
            width: 100%;
            height: auto;
            // background: green;
          }
        }
        .m_bottomBox {
          width: 100%;
          position: relative;
          img {
            width: 100%;
            height: auto;
          }
          .m_playBtn {
            width: 40px;
            height: 40px;
            // background: pink;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // z-index: 100;
          }
        }
      }
    }
    .m_forthWrapper {
      margin: 1px auto;
      width: 100%;
      background: #f6f7fb;
      overflow: hidden;
      padding-bottom: 40px;
      .m_forthContent {
        width: 95%;
        margin: 12px auto;
        // background: green;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 14px;
        text-align: center;
        .m_forthRowItem {
          background-image: linear-gradient(#f5f6f8, #ffffff);
          border: 2px solid #fff;
          width: 47%;
          height: 226px;
          margin-top: 10px;
          .m_itemTitle {
            font-size: 14px;
            margin-top: 10px;
            font-family: PingFangSC-Medium;
          }
          .m_itemBottomName {
            margin-top: 5px;
            margin-left: 6px;
            margin-right: 6px;
            height: 54px;
            font-size: 12px;
            text-align: left;
            // word-break: break-all;
          }
          .m_imageBox {
            width: 83px;
            height: 83px;
            display: flex;
            margin: 1px auto;
            justify-content: center;
            // background: pink;
            img {
              margin-top: 8px;
              width: 81px;
              height: 82px;
            }
          }
        }
      }
    }
    .m_fifthWrapper {
      width: 100%;
      height: 228px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .m_imgBox {
        position: relative;
        width: calc(100% - 70px);
        // background: tomato;
        height: 100%;
        .leftShowImg {
          width: 100%;
          height: 100%;
        }
        .m_textDiv {
          position: absolute;
          font-size: 13px;
          top: 15px;
          left: 10px;
          .m_left_indexSpan {
            font-size: 15px;
          }
          .blackGroundColor{
            color: #fff;
          }
          .m_textSpan {
            font-size: 12px;
            font-weight: bold;
            margin-left: 5px;
          }
          .blackGroundColor{
            color: #fff;
          }
        }
      }
      .m_fifthRightDiv {
        width: 50px;
        // background: rebeccapurple;
        position: relative;
        display: flex;
        img {
          width: 50px;
          height: 227px;
        }
        .digitalSpan {
          position: absolute;
          bottom: 8px;
          right: 16px;
        }
      }
    }
  }
}
</style>

